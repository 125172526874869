import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Hero from '../components/Screens/Hero';

// ====

const Contact = (props) => {
  const { data } = props;

  let doc;
  if (data) {
    doc = JSON.parse(data.index.content);
  }

  return (
    <Layout meta={doc.meta}>
      <Hero title={doc.title} copy={doc.body} image={doc.image} />
    </Layout>
  );
};

export default Contact;

// ====

export const QUERY = graphql`
  query ContactDefaultQuery {
    index: storyblokEntry(slug: { eq: "contact" }, lang: { eq: "default" }) {
      content
    }
  }
`;
